const disclosureBtns = document.querySelectorAll('[data-disclosure]');

const showNav = function (btn, target) {
  btn.setAttribute('aria-expanded', 'true');

  if (target) {
    target.classList.remove('js-hidden');
    target.scrollIntoView();
  }
};

const hideNav = function (btn, target) {
  btn.setAttribute('aria-expanded', 'false');

  if (target) {
    target.classList.add('js-hidden');
  }
};

disclosureBtns.forEach((btn) => {
  const target = document.getElementById(btn.getAttribute('aria-controls'));
  if (target && btn.getAttribute('aria-expanded') === 'false') {
    target.classList.add('js-hidden');
  }

  btn.addEventListener('click', () => {
    if (btn.getAttribute('aria-expanded') === 'false') {
      showNav(btn, target);
    } else {
      hideNav(btn, target);
    }
  });
});
